import React, { useState } from 'react';
import { Link } from "react-router-dom";
import queryString from 'query-string';

import './Join.css';

const Join = ({ location }) => {

  const room2  = queryString.parse(location.search);

  var min=100000; 
  var max=1000000;  
  var random = Math.floor(Math.random() * (max - min)) + min; 

  const [name, setName] = useState('');
  const [room, setRoom] = useState(room2.room || random);

  if(room==null){
    room=getRandomInt(100000,1000000)
  }
  
  return (
    <div className="joinOuterContainer">
      <div className="joinInnerContainer">
        <h1 className="heading">Chronotos<div className="beta">beta</div> </h1> 
        <div>
          <input 
            placeholder="Pseudo" 
            className="joinInput" 
            type="text" 
            onChange={(event) => setName(event.target.value)} 
            onKeyPress={event => room && event.key === 'Enter' ? document.getElementById("login").click() : null}
            />
        </div>
        <div>
          <input value={room} placeholder="ID partie" className="joinInput mt-20" type="text" 
          onChange={(event) => setRoom(event.target.value)}
          onKeyPress={event => room && event.key === 'Enter' ? document.getElementById("login").click() : null}
          />
        </div>
        <Link onClick={e => (!name || !room) ? e.preventDefault() : null} to={`/chat?name=${name}&room=${room}`}>
          <button id="login" className={'button mt-20'} type="submit">Connexion</button>
        </Link>
      </div>
    </div>
  );


}

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

export default Join;